<template>
    <div class="question-choice__wrapper">
        <div class="question-choice"
             :class="checkChoicesIconStatus && 'question-choice--no-icon'">
            <label v-for="choice in step.choices"
                   :key="choice.id"
                   class="question-choice__label"
                   :class="getLabelClass(choice.icon)"
            >
                <input type="radio"
                       class="choice-box"
                       :name="step.step_key"
                       @click="save($event)"
                       :value="choice.value"
                       :disabled="isDisabled"
                       :checked="retrieve(choice.value)">
                <div class="box">
                    <div class="box__icon__wrapper" v-if="choice.icon">
                        <svgicon :name="step.step_key + '__' + choice.value"
                                 :original="true"
                                 height="48">
                        </svgicon>
                    </div>
                    <div class="box__label" :id="choice.value">
                        {{ choice.label }}
                    </div>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
import appConfig from '@/config/app.json';
import navCommonMixin from '@/mixins/mixin.navCommon';
import { eT } from '@/utils/eventTracking';
import '@/compiled-icons';

export default {
    mixins: [
        navCommonMixin,
    ],
    data() {
        return {
            current: this.$route.params.id,
            step: {},
            isDisabled: false,
        };
    },
    computed: {
        checkChoicesIconStatus() {
            return this.step.choices.every(choice => choice.icon === false);
        },
        checkForNoIconStyle() {
            const { length } = this.step.choices;

            if (length >= 2) {
                const [secondLastItem, lastItem] = this.step.choices.slice(-2);
                if (length % 2 === 0) {
                    return !lastItem.icon && !secondLastItem.icon;
                }
            }
            return false;
        },
    },
    methods: {
        /**
         * Helper to convert a string to a boolean.
         * @param value: {string}
         * @returns {boolean}
         */
        convertToBool(value) {
            return value === 'true';
        },

        /**
         * Saves the user selection to the Vuex store.
         * @param event: {event}
         * @returns {boolean}
         */
        save(event) {
            eT({
                event: 'submit_answer',
                question_label: appConfig.steps[this.$route.params.id].label,
                button_label: document.getElementById(event.target.value).innerText,
                button_value: event.target.value,
            });

            this.isDisabled = true;
            let choice = event.target.value;

            if (choice === 'true' || choice === 'false') {
                choice = this.convertToBool(choice);
            }

            this.$store.commit('collectedData/answers/addAnswerEntry', {
                current: this.step.backend_key,
                answer: choice,
            });

            this.$store.dispatch('ui/setTransition', true);

            setTimeout(() => {
                this.isDisabled = false;
                this.$store.dispatch('ui/setTransition', false);
                this.navigateNextStep(choice);
            }, 400);
        },

        /**
         * Retrieves the value potentially already stored in the Vuex store.
         * It is used to pre-select the option for the user.
         * @param value: {any}
         * @returns {boolean}
         */
        retrieve(value) {
            return value === this.$store.state.collectedData.answers[this.step.step_key];
        },

        /**
         * Filter out step choices flagged as "show: false" in src/config/app.json.
         * Please also note that it will filter out choices missing the "show" key. Only the choices
         * with "show: true" will be allowed.
         * @param step
         * @returns step with filtered choices
         */
        filterChoices(step) {
            step.choices.forEach((choice, index) => {
                if (!choice.show) {
                    step.choices.splice(index, 1);
                }
            });
            return step;
        },
        getLabelClass(iconsStatus) {
            if (this.checkForNoIconStyle && !iconsStatus) return 'question-choice__label-no-icon';
            return '';
        },
    },
    created() {
        this.step = this.filterChoices(appConfig.steps[this.current]);
    },
};
</script>
